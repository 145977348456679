import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Strong, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | MotoMystique
			</title>
			<meta name={"description"} content={"Unravel the Road's Secrets"} />
			<meta property={"og:title"} content={"Home | MotoMystique"} />
			<meta property={"og:description"} content={"Unravel the Road's Secrets"} />
			<meta property={"og:image"} content={"https://liltokoy.com/img/bike_2.jpg"} />
			<link rel={"shortcut icon"} href={"https://liltokoy.com/img/3824290.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://liltokoy.com/img/3824290.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://liltokoy.com/img/3824290.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://liltokoy.com/img/3824290.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://liltokoy.com/img/3824290.png"} />
			<meta name={"msapplication-TileImage"} content={"https://liltokoy.com/img/3824290.png"} />
			<meta name={"msapplication-TileColor"} content={"https://liltokoy.com/img/3824290.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://liltokoy.com/img/bike_2.jpg) center/cover"
			padding="120px 0 50px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text
							color="--lightD2"
							letter-spacing="1px"
							text-transform="uppercase"
							margin="0"
							font="--headline1"
						>
							Welcome to MotoMystique
						</Text>
						<Text as="h1" font="--headline3" md-font="--headline2" margin="10px 0">
							Discover the untamed spirit of motorcycling through a unique collection of bikes and a school experience that is anything but ordinary.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					What we  can offer
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Our Motorcycle Collection
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					At MotoMystique, every bike tells a story. Here's a taste of our curated range:{" "}
					<br />
					<br />
					<Strong>
						Adventure Seekers{" "}
					</Strong>
					- Tackle rough terrains and explore the unbeaten path with our rugged lineup.
					<Strong>
						{" "}
					</Strong>
					<br />
					<Strong>
						Speed Spectres -{" "}
					</Strong>
					For those with a need for speed, these bikes promise adrenaline in every ride.{" "}
					<br />
					<Strong>
						Urban Unwind{" "}
					</Strong>
					- Perfect for city escapades, these bikes combine comfort with style.
					<br />
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://liltokoy.com/img/bike_7.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-8" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					width="100%"
				>
					<Image
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://liltokoy.com/img/school_4.jpg"
						object-fit="cover"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-order="1"
				justify-content="flex-start"
				align-items="flex-start"
				padding="24px 24px 24px 24px"
			>
				<Box>
					<Text
						font="--base"
						lg-text-align="center"
						text-transform="uppercase"
						margin="0px 0px 8px 0px"
						color="--light"
						letter-spacing="2px"
					>
						More Than Just Riding
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--light"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						MotoMystique School
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-text-align="center">
					Embarking on the motorcycling journey is more than just learning to ride, it’s about understanding the soul of the road and the machine.
					<br />
					<br />
					<Strong>
						Twilight Lessons
					</Strong>
					{" "}- Experience the serenity of the roads during twilight hours, a unique session aimed at building confidence in different light conditions.
					<br />
					<Strong>
						Terrain Trials -
					</Strong>
					{" "}Instead of confining lessons to tarmac, we introduce riders to various terrains, ensuring they're road-ready for any adventure.
					<br />
					<Strong>
						Mindful Motorcycling
					</Strong>
					{" "}- A blend of meditation and riding. Understand the essence of being present on the road and making every journey a mindful experience.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Join The MotoMystique Community
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Be part of a close-knit community where you can share your rides, experiences, and stories. Dive into monthly ride-along events, weekend getaways, and workshops to fuel your passion.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contact"
			>
				Join Community
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});